<template>
  <section>
    <Header />
    <Navbar v-bind:linksExtended="linksExtended" :links="links" />
    <article class="faq__article">
      <h1>
        Гражданам Украины, Казахстана и Молдовы будут выдавать РВП без квоты
        (новый закон)
      </h1>
      <p>
        В соответствии с пп.”б” п.4 ст.1 Федерального закона от 01.07.2021 №
        274-ФЗ “О внесении изменений в Федеральный закон о правовом положении
        иностранных граждан в Российской Федерации” (№ 115-ФЗ), пп.11, п.3, ст.6
        разрешение на временное проживание (РВП) может быть выдано гражданину
        Украины, Молдовы и Казахстана без учета утвержденной Правительством
        Российской Федерации квоты.
      </p>
      <p>
        То есть, теперь все граждане этих 3 стран могут оформить РВП на
        территории России, основанием для оформления РВП, является наличие
        гражданства Украины, Молдовы и Казахстана.
      </p>
    </article>
    <Footer />
  </section>
</template>

<script>
import Header from "../Header.vue";
import Navbar from "../Navbar.vue";
import Footer from "../Footer.vue";

export default {
  name: "RVP4",
  components: { Header, Navbar, Footer },
  data() {
    return {
      linksExtended: [
        {
          id: "Экзамен",
          text: "Экзамен",
          sublinks: [
            {
              id: "Для патента",
              text: "Для патента",
              link: "/dlya-patenta",
            },
            {
              id: "Для РВП",
              text: "Для РВП",
              link: "/dlya-rvp",
            },
            {
              id: "Для ВНЖ",
              text: "Для ВНЖ",
              link: "/dlya-vnj",
            },
            // {
            //   id: "Для гражданства",
            //   text: "Для гражданства",
            //   link: "/dlya-grajdanstva",
            // },
          ],
        },
        {
          id: "РВП",
          text: "РВП",
          sublinks: [
            {
              id: "Основания получения РВП",
              text: "Основания получения РВП",
              link: "/osnovaniya-polucheniya-rvp",
            },
            {
              id: "РВП по квоте",
              text: "РВП по квоте",
              link: "/rvp-po-kvote",
            },
            {
              id: "РВП без квоты",
              text: "РВП без квоты",
              link: "/rvp-bez-kvoti",
            },
            {
              id: "РВП без КВОТЫ (Украина, Молдова, Казахстан )",
              text: "РВП без КВОТЫ (Украина, Молдова, Казахстан )",
              link: "/rvp-bez-kvoti-dop",
            },
          ],
        },
        {
          id: "ВНЖ",
          text: "ВНЖ",
          sublinks: [
            {
              id: "Основания получения ВНЖ",
              text: "Основания получения ВНЖ",
              link: "/osnovaniya-polucheniya-vnj",
            },
            {
              id: "ВНЖ в упрощенном порядке",
              text: "ВНЖ в упрощенном порядке",
              link: "/vnj-v-uproshennom-poryadke",
            },
          ],
        },
        {
          id: "НРЯ",
          text: "НРЯ",
          sublinks: [
            {
              id: "Основания получения НРЯ",
              text: "Основания получения НРЯ",
              link: "/osnovaniya-polucheniya-nrya",
            },
          ],
        },
        {
          id: "Гражданство",
          text: "Гражданство",
          sublinks: [
            {
              id: "Основания для получения гражданства РФ",
              text: "Основания для получения гражданства РФ",
              link: "/osnovaniya-dlya-polucheniya-grajdanstva-rf",
            },

            {
              id: "Гражданство по НРЯ",
              text: "Гражданство по НРЯ",
              link: "/grajdanstvo-po-nrya",
            },
            {
              id: "Гражданство РФ для детей",
              text: "Гражданство РФ для детей",
              link: "/grajdanstvo-rf-dlya-detei",
            },
            {
              id: "Гражданство РФ по браку",
              text: "Гражданство РФ по браку",
              link: "/grajdanstvo-rf-po-braku",
            },
          ],
        },
      ],
      links: [
        {
          id: "О нас",
          text: "О нас",
          link: "/o-nas",
        },
        {
          id: "Контакты",
          text: "Контакты",
          link: "/kontakti",
        },
      ],
      cards: [
        {
          id: 1,
          title: "Перевод документов",
          price: "от 700₽",
          list: [
            "Переводы документов (количество требуемых на подачу)",
            "Заявление в 2 –х экземплярах",
            "Экзамен на знание русского языка",
            "Проверка документов (на сроки и.т.п)",
            "Заполнение автобиографии",
            "Сопровождение до сдачи документов",
            "Консультация по любым вопросам",
          ],
        },
        { id: 2, title: "РВП/ВНЖ", price: "25000₽" },
        // { id: 3, title: "ВНЖ", price: "25000₽" },
        { id: 3, title: "Гражданство", price: "15000₽" },
        { id: 4, title: "Квота", price: "6000₽" },
        {
          id: 5,
          title: "Экзамен ( РВП,ВНЖ,Гражданство ,Патент )",
          price: "от 3500₽",
        },
      ],
      reasons: [
        {
          id: "economy",
          img: "economy.svg",
          title: "Экономия",
          paragraph: "Самые низкие цены в регионе",
        },

        {
          id: "office",
          img: "office.svg",
          title: "Офис рядом с ОВМ",
          paragraph: "Находимся в 100 м от отдела по вопросам миграции",
        },
        {
          id: "guarantee",
          img: "guarantee.svg",
          title: "Гарантия качества",
          paragraph: "Оказываем все услуги в установленный срок",
        },
        {
          id: "folder",
          img: "folder.svg",
          title: "Полный комплекс услуг",
          paragraph: "Любые миграционные услуги в одном месте",
        },
        {
          id: "card",
          img: "card.svg",
          title: "Прозрачная оплата",
          paragraph: "Оплата за фактические услуги. Без скрытых платежей",
        },
      ],
      reviews: [
        {
          id: "Владимир Суховеров",
          image: "suhoverov.jpg",
          text:
            "Готовили документы для вида на жительство. Все сделано професиионально, в короткие сроки без лишних слов и суеты, на все вопросы всегда получал исчерпывающие ответы. Особо хочу поблагодарить Кристину Максимовну и Марию Витальевну!!!! Очень хорошие специалисты, рекомендую! Друзья белорусы делали у них ВНЖ, тоже остались очень довольны работой. Документы подал с первого раза!!",
          name: "Владимир Суховеров",
        },
        {
          id: "Ольга С.",
          image: "olga.jpg",
          text:
            "Делали документы для ВНЖ гражданке Беларусь. Все очень быстро и качественно, девочки работают с каждым клиентом индивидуально, помнят о вас и ведут до сдачи документов, помогут во всем разобраться и все разжуют. Следующий этап гражданство, только к ним и только с ними. Спасибо, что в нашем районе есть такой центр. Вы лучшие!!!",
          name: "Ольга С.",
        },
        {
          id: "Алексей Журавель",
          image: "juravel.jpg",
          text:
            "Подавал документы на ВНЖ. Очень вежливые и грамотные специалисты. Помогают и консультируют по всем вопросам, возникающим в процессе оформления документов. Все вопросы стараются решить максимально быстро. P.S. Самые доступные цены на переводы документов по Подольску.",
          name: "Алексей Журавель",
        },
        {
          id: "Лиза Пронько",
          image: "pronko.webp",
          text:
            "Огромное спасибо хочу сказать Марии! Сначала она помогала с оформлением документов ВНЖ и на гражданство моего мужа, а теперь мне помогает в оформлении РВП. Буду и дальше с ней сотрудничать. Мария ответственный и хороший специалист!",
          name: "Лиза Пронько",
        },
        {
          id: "Елена Ч.",
          image: "cherkizova.jpg",
          text:
            "Очень пожалели, что уже на последнем этапе оформления открыли для себя этот миграционный центр. Девочки, почему не раньше, мы через такие муки ада прошли с РВП, потом с ВНЖ и наконец встретили вас.Спасибо большое за помощь, доброжелательность, компетентную консультацию и сбор всех необходимых документов. На гражданство подали с первого раза. Очень вам благодарны, особенно Кристиночке, которая переживала за нас больше всех. Всем советую, не теряйте время и свои нервы (здоровье важнее), только к ним.",
          name: "Елена Ч.",
        },
        {
          id: "Вероника А.",
          image: "vera.webp",
          text:
            "Выражаю благодарность Кристине за профессионализм и оперативную помощь при подготовке анкеты на гражданство РФ. Приняли документы с первого раза. От общения с Кристиной остались самые приятные впечатления.",
          name: "Вероника А.",
        },
        {
          id: "Sky17 R.",
          image: "sky.jpg",
          text:
            "Обслуживание клиентов на высоте. Подавал документы на гражданство, выполнено на все 100%, придраться не к чему. Главное вежливые и старательно выполняют свою работу. Лучшие в Москве и области, советую.",
          name: "Sky17 R.",
        },
        {
          id: "Павел Савенок",
          image: "pavel.jpg",
          text:
            "Девочки доброжелательные, особо хочу выделить Кристину Максимовну, старательная девочка, всегда поможет и сделает все как надо) спасибо",
          name: "Павел Савенок",
        },
      ],
    };
  },
};
</script>

<style>
.rvp {
  max-width: 90%;
}
</style>
